import { MUISelect } from "@abb/abb-arcadia-ui-react";

interface DropdownProps {
  items?: { name?: string; id: string }[];
  label?: string;
  name?: string;
  placeholder?: string;
  onChange?: (data: { value: string; label: string }[]) => void;
  multiSelect?: boolean;
  disabled?: boolean;
  minWidth?: number;
  selectedItems?: string[];
  enableSearch: boolean;
}

export default function CustomDropdown({
  items = [],
  label,
  name,
  placeholder,
  onChange,
  multiSelect = false,
  disabled = false,
  minWidth = 240,
  selectedItems,
  enableSearch
}: DropdownProps) {
  const handleChange = (newSelectedIds: string | string[]) => {
    // Ensure newSelectedIds is always an array, even for single selections
    const updatedSelectedIds = Array.isArray(newSelectedIds)
      ? newSelectedIds
      : [newSelectedIds];

    const selectedItems = updatedSelectedIds.map((id) => {
      const selectedItem = items.find((item) => item.id === id);
      return { value: id, label: selectedItem?.name || "" };
    });
    onChange?.(selectedItems);
  };

  return (
    <MUISelect
      enableSearch={enableSearch}
      dropdownLOV={items}
      label={label || ""}
      minWidth={minWidth}
      isDisabled={disabled}
      multiSelect={multiSelect}
      name={name}
      placeholder={placeholder || ""}
      size={32}
      onChange={handleChange}
      selectedItem={selectedItems && selectedItems}
      handleCloseIconClick={() => {
        onChange?.([]);
      }}
    />
  );
}
