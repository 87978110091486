import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { Space } from "antd";
import {
  AppGeneralTextContext,
  ImagesContext,
} from "./app_context/app_context";
import { SEARCH_TRIGGER_LENGTH } from "./components/constants";
import BannerImage from "./banner_image";
import { getAllApplicationConfig } from "./services/myPnpServices";
import { Input } from "@abb/abb-common-ux-react";
import { MUITextField } from "@abb/abb-arcadia-ui-react";

const Extensions = () => {
  const navigate = useNavigate();
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const imagesData = React.useContext(ImagesContext);
  const { images }: any = imagesData;
  const [access_token, setAccessToken] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortEnabled, setSortEnabled] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [allExtensions, setAllExtensions] = useState<any>([]);
  const [isEmptyResponse, setIsEmptyResponse] = useState<boolean>(false);

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      setAccessToken(token);
    }
  }, []);

  useEffect(() => {
    if (access_token) {
      fetchAllAppsConfig();
    }
  }, [access_token]);
  const fetchAllAppsConfig = async () => {
    try {
      const serviceResp = await getAllApplicationConfig(2);
      if (serviceResp.length > 0) {
        const sortedApps = sortApps(serviceResp);
        setAllExtensions(sortedApps);
        setIsEmptyResponse(false);
      } else {
        setIsEmptyResponse(true);
      }
    } catch (error) {
      setIsEmptyResponse(true);
    }
  };
  const sortApps = (data: any) => {
    return data.sort((a: any, b: any) => {
      if (a.tileOrder === b.tileOrder) {
        return a.name.localeCompare(b.name);
      }
      return a.tileOrder - b.tileOrder;
    });
  };
  const handleSearchChange = (value: any) => {
    setSearchTerm(value);
    setSearchInput(value.length > SEARCH_TRIGGER_LENGTH ? value : "");
  };
  const toggleSortOrder = () => {
    setSortEnabled(true);
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };
  function containsDomain(url: string) {
    const domainPattern = /https?:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    return domainPattern.test(url);
  }

  const AppBlock = ({ data }: any) => {
    return (
      <div className="blocks" key={data.id}>
        <div className="app-info">
          <div className="image-container">
            <div>
              <img
                src={`../assets/images/${data.icon}`}
                alt={data.name}
                style={{
                  filter: "var(--icon-filter-header)",
                }}
              />
            </div>
          </div>
          <div className="app-name">
            <h4>{data.name}</h4>
          </div>
        </div>
        <div className="box-content">
          <p
            title={data?.description.length > 95 ? data.description : undefined}
          >
            {data?.description.length > 95
              ? data.description.slice(0, 95) + " ..."
              : data.description}
          </p>
        </div>
        <div className="app-link">{general_text_data.go_to_app}</div>
      </div>
    );
  };

  return (
    <div className="services-container">
      <div className="intro-header">
        <h1>Extensions</h1>
      </div>
      <div className="intro-banner-container">
        <BannerImage />
      </div>
      <div className="intro-content"> {general_text_data.description_home}</div>

      <div className="services-content">
        <div className="misc-actions-container mr-20">
          <div className="cta-btns">
            <Space>
              <MUITextField
                onChange={handleSearchChange}
                onKeyDown={() => {}}
                placeholder={"Search for Application"}
                size={32}
              />
              <div className="icon-sort" onClick={toggleSortOrder}>
                <img
                  className="icon-sort"
                  src={
                    sortOrder === "asc"
                      ? `${images.sort_icon_asc}`
                      : `${images.sort_icon_dsc}`
                  }
                  alt=""
                  title={
                    sortOrder === "asc"
                      ? "Sort in descending order"
                      : "Sort in ascending order"
                  }
                  style={{ filter: "var(--icon-filter-header)" }}
                />
              </div>
            </Space>
          </div>
        </div>
        {isEmptyResponse ? (
          <div className="empty-message myapp-headings ">
            You dont have permission to view the applications, please contact
            Administrator.
          </div>
        ) : (
          <div className=" grid-container">
            {allExtensions !== undefined &&
              allExtensions.length > 0 &&
              allExtensions
                .filter((app: any) =>
                  app.name.toLowerCase().includes(searchInput.toLowerCase())
                )
                .sort((a: any, b: any) => {
                  if (!sortEnabled) return 0;
                  const comparison = a.name.localeCompare(b.name);
                  return sortOrder === "asc" ? comparison : -comparison;
                })
                .map((data: any) => {
                  const url = data.externalUrl || data.embedUrl;
                  const isExternal = containsDomain(url);
                  return (
                    <div className="blocks-container col-3">
                      {isExternal ? (
                        <Link
                          to={url}
                          reloadDocument={true}
                          target={data.newTab ? "_blank" : "_self"}
                        >
                          <AppBlock data={data} />
                        </Link>
                      ) : (
                        <div
                          className="app-block"
                          onClick={() => navigate(url)}
                        >
                          <AppBlock data={data} />
                        </div>
                      )}
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Extensions;
