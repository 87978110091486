import React from "react";
import { AppGeneralTextContext } from "./app_context/app_context";
import { Link, useNavigate } from "react-router-dom";

const ApplicationProvisionLanding = () => {
  const navigate = useNavigate();
  const rolesAndUserMenu = [
    {
      appRegid: "776ea3e7-2b20-4b83-b953-0ae565ab04e1",
      name: "Application Provisioning",
      description:
        "Setting up and configuring applications on My Pulp and Paper platform to ensure they are ready for use by end-users.",
      applicationSecurityId: null,
      applicationTypeId: 3,
      applicationTypeName: "Platform Administration",
      adclient: null,
      embedUrl:
        "/platform_administration/manage_applications/Application_Provisioning",
      externalUrl:
        "/platform_administration/manage_applications/Application_Provisioning",
      icon: "icon_application_provisioning.svg",
      isActive: true,
      valuePillar: "Platform Administration",
      tileOrder: 3,
      newTab: false,
      createdBy: "srikanth.manikanti@in.abb.com",
      createdDate: "2024-08-26T09:55:24.71",
      lastUpdatedBy: "srikanth.manikanti@in.abb.com",
      lastUpdatedDate: "2024-08-26T09:55:24.71",
    },
    {
      appRegid: "0f877145-1b21-4d52-a60b-618b16898485",
      name: "Import/Export Application",
      description:
        "Allows users to transfer data and configurations between different environments or systems.",
      applicationSecurityId: null,
      applicationTypeId: 3,
      applicationTypeName: "Platform Administration",
      adclient: null,
      embedUrl:
        "/platform_administration/manage_applications/Import_Export_Application",
      externalUrl:
        "/platform_administration/manage_applications/Import_Export_Application",
      icon: "icon_import_export.svg",
      isActive: true,
      valuePillar: "Platform Administration",
      tileOrder: 21,
      newTab: false,
      createdBy: "srikanth.manikanti@in.abb.com",
      createdDate: "2024-09-02T08:14:03.59",
      lastUpdatedBy: "srikanth.manikanti@in.abb.com",
      lastUpdatedDate: "2024-09-02T08:14:03.59",
    },
  ];
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  function containsDomain(url: string) {
    const domainPattern = /https?:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    return domainPattern.test(url);
  }
  const AppBlock = ({ data, imgSrc }: any) => {
    return (
      <div className="blocks" key={data.id}>
        <div className="app-info">
          <div className="image-container">
            <div>
              <img
                src={imgSrc}
                alt={data.name}
                style={{
                  filter: "var(--icon-filter-header)",
                }}
              />
            </div>
          </div>
          <div className="app-name">
            <h4>{data.name}</h4>
          </div>
        </div>
        <div className="box-content">
          <p
            title={data?.description.length > 95 ? data.description : undefined}
          >
            {data?.description.length > 95
              ? data.description.slice(0, 95) + " ..."
              : data.description}
          </p>
        </div>

        <div className="app-link">{general_text_data.go_to_app}</div>
      </div>
    );
  };
  return (
    <div className="services-container">
      <div>
        <div className="intro-header">
          <h1>Manage Applications</h1>
        </div>
        <div className="menu-container">
          <div className=" grid-container">
            {rolesAndUserMenu.map((data: any) => {
              let url;
              if (data.name === "Roles & User Management") {
                url = "/platform_administration/roles_user_management";
              } else {
                url = data.externalUrl || data.embedUrl;
              }
              const openInNewTab = data.newTab;
              const imgSrc = `../assets/images/${data.icon}`;
              const isExternal = containsDomain(url);
              return (
                <div className="blocks-container col-3">
                  {isExternal ? (
                    <Link
                      to={url}
                      reloadDocument={true}
                      target={openInNewTab ? "_blank" : "_self"}
                    >
                      <AppBlock data={data} imgSrc={imgSrc} />
                    </Link>
                  ) : (
                    <div className="app-block" onClick={() => navigate(url)}>
                      <AppBlock data={data} imgSrc={imgSrc} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationProvisionLanding;
