/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  type AxiosResponse,
  type AxiosBasicCredentials,
  type ResponseType,
} from "axios";
import Cookies from "js-cookie";

const {
  REACT_APP_URL_PREFIX,
  REACT_APP_ENV_PREFIX,
  REACT_APP_DOMAIN,
  REACT_APP_LOCATION,
} = process.env;
export interface IRequestOptions {
  headers?: any;
  basicAuth?: AxiosBasicCredentials;
  responseType?: ResponseType;
}
let onRequestStart: () => void;
let onRequestEnd: () => void;
let onRequestRefreshToken: () => void;
// let onRequestNotification: (errmsg: any) => void;

export const hostURL = `${REACT_APP_URL_PREFIX}${REACT_APP_ENV_PREFIX}${REACT_APP_LOCATION}${REACT_APP_DOMAIN}`;

let BASE_url
if (REACT_APP_LOCATION !== undefined) {
  BASE_url = `${REACT_APP_URL_PREFIX}${REACT_APP_ENV_PREFIX}${REACT_APP_LOCATION}${REACT_APP_DOMAIN}`
} else {
  BASE_url = `${REACT_APP_URL_PREFIX}${REACT_APP_ENV_PREFIX}${REACT_APP_DOMAIN}`
}

//Todo add base url
export const baseURL = `${BASE_url}`;

const onRequest = async (config: any) => {
  const token: any = Cookies.get("access_token");
  if (token && config.url.includes("GetSiteHierarchyTreeView")) {
    config.headers["Authorization"] = ` bearer ${token}`;
    config.headers["Cache-control"] = ` public`;
  } else {
    config.headers["Authorization"] = ` bearer ${token}`;
  }
  return config;
};

export const c = (callback: any) => {
  onRequestStart = callback;
};
export const addRequestStartListener = (callback: any) => {
  onRequestStart = callback;
};
export const addRequestEndListener = (callback: any) => {
  onRequestEnd = callback;
};
export const addRefreshToken = async (callback: any) => {
  onRequestRefreshToken = callback;
};
// export const addNotification = (callback: any) => {
//   onRequestNotification = callback("");
// };

const onResponseSuccess = (
  response: any
): AxiosResponse<any, any> | Promise<AxiosResponse<any, any>> => {
  onRequestEnd?.();
  return response;
};

const onResponseError = async (error: any): Promise<any> => {
  onRequestEnd?.();
  const originalRequest = error.config;
  if (error?.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    await onRequestRefreshToken();
    const userData = localStorage.getItem("userTokenData");
    if (userData) {
      const token = JSON.parse(userData);

      if (token?.data?.access_token) {
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token.data.access_token}`;
        return axiosInstance(originalRequest);
      }
    } else {
      console.error("No user data found in localStorage.");
    }
  }
  // else if (error?.response?.status === 400) {
  //   console.log("error", error?.response);
  //   onRequestNotification(error?.response);
  // } 
  else return Promise.reject(error);
};

export const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 1000 * 60 * 1,
  validateStatus: function (status) {
    return status === 200 || status === 201 || status === 204;
  },
});

axiosInstance.interceptors.request.use(onRequest);
axiosInstance.interceptors.response.use(onResponseSuccess, onResponseError);

export async function Get<T, D>(
  endPoint: string,
  params?: D,
  requestOptions: IRequestOptions = {}
): Promise<T> {
  onRequestStart?.();
  const res = await axiosInstance.get<T, AxiosResponse<T>, D>(endPoint, {
    params,
    headers: requestOptions.headers,
    responseType: requestOptions.responseType,
  });
  return res.data;
}

export async function Post<T, D>(
  endPoint: string,
  data?: D,
  requestOptions: IRequestOptions = {}
): Promise<AxiosResponse<T>> {
  onRequestStart?.();

  const res = await axiosInstance.post<T, AxiosResponse<T>, D>(endPoint, data, {
    headers: requestOptions.headers !== null ? requestOptions.headers : {},
    auth: requestOptions.basicAuth,
  });
  return res;
}

export async function Put<T, D>(
  endPoint: string,
  data: D,
  requestOptions: IRequestOptions = {}
): Promise<T> {
  const res = await axiosInstance.put<T, AxiosResponse<T>, D>(endPoint, data, {
    headers: requestOptions.headers,
  });
  return res.data;
}

export async function Delete<T, D>(
  endPoint: string,
  data: D,
  requestOptions: IRequestOptions = {},
  displayLoader = true
): Promise<T> {
  const res = await axiosInstance.delete<T, AxiosResponse<T>, D>(endPoint, {
    headers: requestOptions.headers,
    data,
  });
  return res?.data;
}
