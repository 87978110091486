import React from "react";
import Cookies from "js-cookie";
import { AppGeneralTextContext } from "./app_context/app_context";
import { Icon } from "@abb/abb-common-ux-react";
import { useNavigate } from "react-router-dom";

const ContactABB: React.FC = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const navigate = useNavigate();

  const handleRaiseTickek = () => {
    const userDataCookie = Cookies.get("user_data");
    const user_data = userDataCookie ? JSON.parse(userDataCookie) : null;
    if (user_data) {
      navigate("/contact_abb/casecreationform", {
        state: {
          userName: `${user_data.given_name} ${user_data.family_name}`,
          email: user_data.email,
        },
      });
    }
  };

  return (
    <div className="role-deinition-screen">
      <h1>{general_text_data.contact_abb}</h1>
      <div className="new-role-container">
        <div className="new-role-title-container">
          <h3 className="new-role-title">{general_text_data.contact_us}</h3>
        </div>
        <div className="contact-abb-container">
          <div className="grid-container">
            <div className="col-5">
              <div
                className="contact-abb-cards"
                onClick={() =>
                  navigate(
                    "/platform_administration/roles_user_management/user_mapping/invite_new_user",
                    { state: { type: "user_self_request" } }
                  )
                }
              >
                <Icon
                  name="abb/plus-in-circle"
                  sizeClass="medium"
                  className="mt-3"
                />
                <div className="contact-abb-card-text">
                  {general_text_data.request_for_extension_application}
                </div>
              </div>
            </div>
          </div>
          <div className="grid-container  mt-20">
            <div className="col-5">
              <div
                className="contact-abb-cards"
                onClick={() => {
                  const currentLocation = window.location.href;
                  if (
                    !currentLocation.includes("https://mypulpandpaper.abb.com")
                  ) {
                    navigate("/serviceproAccessRequest");
                  }
                }}
              >
                <Icon
                  name="abb/plus-in-circle"
                  sizeClass="medium"
                  className="mt-3"
                />
                <div className="contact-abb-card-text">
                  {general_text_data.request_for_service_pro_application}
                </div>
              </div>
            </div>
          </div>
          <div className="grid-container mt-20">
            <div className="col-5">
              <div className="contact-abb-cards" onClick={handleRaiseTickek}>
                <Icon
                  name="abb/plus-in-circle"
                  sizeClass="medium"
                  className="mt-3"
                />
                <div className="contact-abb-card-text">
                  {general_text_data.raise_the_ticket_form_to_case}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactABB;
