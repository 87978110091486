import React from "react";
import { ImagesContext } from "./app_context/app_context";

const BannerImage = () => {
  const imagesData = React.useContext(ImagesContext);
  const { images }: any = imagesData;

  const wrapper: any = document.querySelector(".carousel__wrapper");

  let handler: ReturnType<typeof setTimeout>;
  const updateCarouselPosition = (slide: number) => {
    wrapper.style.animation = `none`;
    wrapper.style.transform = `translateX(-${slide}%)`;

    clearTimeout(handler);

    handler = setTimeout(() => {
      wrapper.style.animation = `slide 6s infinite ease-in-out`;
    }, 5000);
  };

  return (
    <div className="bannerimg-container">
      <section className="carousel" aria-label="Gallery">
        <div className="carousel__wrapper">
          <div id="carousel__slide1" className="carousel__slide">
            <img
              src={images.pnp_making}
              alt="mission"
              className="carousel__image"
            />
            <div className="carousel__snapper"></div>
          </div>
          <div id="carousel__slide2" className="carousel__slide">
            <img
              src={images.pnp_machine}
              alt="purpose"
              className="carousel__image"
            />
            <div className="carousel__snapper"></div>
          </div>
          <div id="carousel__slide3" className="carousel__slide">
            <img
              src={images.pnp_rolls}
              alt="vision"
              className="carousel__image"
            />
            <div className="carousel__snapper"></div>
          </div>
        </div>
        <aside className="carousel__navigation">
          <ol className="carousel__navigation-list">
            <li className="carousel__navigation-item">
              <a
                className="carousel__navigation-button"
                aria-current="true"
                onClick={() => updateCarouselPosition(0)}
              >
                Go to slide 1
              </a>
            </li>
            <li className="carousel__navigation-item">
              <a
                className="carousel__navigation-button"
                onClick={() => updateCarouselPosition(33.33)}
              >
                Go to slide 2
              </a>
            </li>
            <li className="carousel__navigation-item">
              <a
                className="carousel__navigation-button"
                onClick={() => updateCarouselPosition(66.66)}
              >
                Go to slide 3
              </a>
            </li>
          </ol>
        </aside>
      </section>
    </div>
  );
};

export default BannerImage;
